/**
 * ArticlePlainListComponent
 *
 * Displays a simple list of Articles
 *
 * @Input()     articles     Array<ArticleModel>          Array of articles to display
 * @Input()     canEdit:     boolean                      Does the current user have edit privileges
 * @Output()    open:        EventEmitter<ArticleModel>   Emitted when a user clicks on the title to open the article
 * @Output()    edit:        EventEmitter<ArticleModel>   Emitted when a user/editor clicks on the edit button to edit the article
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArticleModel } from '../../../models/article-model';
import { ChannelDefModel } from '../../../models/settings/channel-def-model';
import { Router } from '@angular/router';
import { RolesService } from '../../../services/roles.service';

@Component({
  selector: 'app-article-plain-list',
  templateUrl: './article-plain-list.component.html',
  styleUrls: ['./article-plain-list.component.css']
})
export class ArticlePlainListComponent implements OnInit {
  @Input() articles: Array<ArticleModel> = [];
  @Input() canEdit: boolean = false;
  @Input() channelDefs: Array<ChannelDefModel> = [];
  @Output() open: EventEmitter<ArticleModel> = new EventEmitter<ArticleModel>();
  @Output() edit: EventEmitter<ArticleModel> = new EventEmitter<ArticleModel>();
  @Input() expanded: boolean = false;

  public isEditor = false;
  //public expanded: boolean = true;


  constructor(private router: Router, public rolesService: RolesService) {
    this.isEditor = rolesService.isEditor;
    // this.originalList = this.articles;
  }

  ngOnInit() {

  }

  newArticle() {
   // console.log('newarticle');
    this.router.navigate(['/articles/edit', '']);
  }

}




