import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-imageselector',
  templateUrl: './imageselector.component.html',
  styleUrls: ['./imageselector.component.css']
})
export class ImageselectorComponent implements OnInit {
  @Input() images = [];
  @Output() selectedImageEmitter = new EventEmitter();
  selected = '';

  constructor() { }

  ngOnInit() {
  }

  selectImage(inSrc) {
    this.selectedImageEmitter.emit(inSrc);
  }
}
