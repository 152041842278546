/**
 * ArticleItemPlainComponent
 *
 * Displays a single article item in an article list.
 *
 * @Input()   article   ArticleModel    The article to be displayed
 * @Input()   canEdit   boolean         Is the current user allowed to edit
 * @Input()   expanded  boolean         If true show title, summary and image, if false only title
 *
 * @Output()  open                      Emitted when a user clicks on the title to open the article
 * @Output()  edit                      Emitted when a user/editor clicks on the edit button to edit the article
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArticleModel } from '../../../../models/article-model';
import { Routes, Router } from '@angular/router';
import { ChannelDefModel } from '../../../../models/settings/channel-def-model';
import { RolesService } from '../../../../services/roles.service';

@Component({
  selector: 'app-article-item-plain',
  templateUrl: './article-item-plain.component.html',
  styleUrls: ['./article-item-plain.component.css']
})
export class ArticleItemPlainComponent implements OnInit {
  @Input() article: ArticleModel;
  @Input() canEdit: boolean = false;
  @Input() expanded: boolean = false;
  @Input() channelDefs: Array<ChannelDefModel> = [];
  @Output() open: EventEmitter<ArticleModel> = new EventEmitter<ArticleModel>();
  @Output() edit: EventEmitter<ArticleModel> = new EventEmitter<ArticleModel>();

  mayEditByChannel;


  constructor(private router: Router, public rolesService: RolesService) { }

  ngOnInit() {
    this.mayEditByChannel = this.rolesService.mayEditByChannel(this.article);
    

  }

  editArticle() {
    this.router.navigate(['/articles/edit', this.article.key]);
  }

  openArticle() {
    this.router.navigate(['/articles/view', this.article.key]);
  }


}


